.content-name {
  display: flex;
  flex-wrap: wrap;
}

.content-name > span {
  font-size: 18px;
}

.content-name > span:nth-child(1) {
  margin-right: 4px;
}

#btn-load-more {
  margin-bottom: 40px;
}

#empty-list {
  margin: 40px;
  text-align: center;
  color: #6b6b6b;
}

table {
  width: 100%;
  margin: 40px 0;
  border-collapse: collapse;
  vertical-align: middle;
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px 1px rgba(9, 30, 66, 0.13);
  background: #fafbfc;
  padding: 2px 0px;
  overflow-x: auto;
}

th,
td {
  padding: 8px;
}
