* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body,
input,
button,
textarea {
  font-family: "Lato", sans-serif;
  line-height: 1;
  color: #1b1b1b;
}
