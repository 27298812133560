button.btn-default {
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn-default:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button.btn-default:hover {
  filter: brightness(0.9);
}
