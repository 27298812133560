.list-item {
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #ddd;
}

.list-item:hover {
  background-color: #f7f7f7;
}

.list-item {
  user-select: none;
  cursor: pointer;
}

.list-item:last-child {
  border-bottom: none;
}

.list-item td {
  text-align: center;
}

.list-item td:last-child {
  float: right;
}

.list-item td:nth-child(4) {
  text-align: left;
}

.expandable p {
  font-size: 14px;
  line-height: 20px;
}

.expandable p:nth-child(even) {
  margin-left: 20px;
  color: #6b6b6b;
}

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
  text-align: left;
}

.column-table {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.inline-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inline-text .text-overflow {
  width: 200px;
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-text span:first-child {
  color: #808080;
}

@media (max-width: 532px) {
  .list-item {
    display: flex;
  }

  .list-item td {
    text-align: none;
  }
}
