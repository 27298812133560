.container-header {
  min-height: 40px;
  width: 100%;

  padding: 6px;
  margin: 0 2px;

  border-bottom: 1px solid #cccccc;
  background: #ffffff;
}

.container-header > th {
  font-weight: 400;
  text-align: center;
}

.container-header th:last-child {
  text-align: right;
}

.container-header th:nth-child(4) {
  text-align: left;
}

@media (max-width: 532px) {
  .container-header {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .container-header > th {
    color: #808080;
  }
}
